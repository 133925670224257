<template>
    <div class="pages">
        <pageHeader></pageHeader>
        <div class="box">
            <div class="main">
                <acountNav></acountNav>
                <div class="acount-box">
                    <div class="acount-header">
                        <div class="acount-header-item">我的关注</div>
                    </div>
                    <div class="acount-main">
                        <div class="follow-lists">
                            <div class="follow-item" v-for="(item, index) in collectionLists" :key="index">
                                <img class="huati-icon" src="../../static//icon/huati-icon.png">
                                <span>{{ item.detail.name }}</span>
                                <img @click="cancelCollect(item.detail.id , item.type , index)" class="x-icon" src="../../static/icon/x-icon.png">
                            </div>
                            <noData v-if="collectionLists.length === 0" title="暂无数据"></noData>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pageFooter></pageFooter>
    </div>
</template>

<script>
import noData from '@/components/noData.vue'
import pageHeader from '@/components/pageHeader.vue'
import pageFooter from '@/components/pageFooter.vue'
import acountNav from '@/components/acountNav.vue'
import { getCollectLists, cancelCollect } from '@/api/collect.js'
export default {
    name: "follow",
    components: {
        pageHeader,
        pageFooter,
        acountNav,
        noData
    },
    data() {
        return {
            collectionLists: [],
            listsRequestdata: {
                page: 1,
                pageSize: 99999,
                filter: {
                    type: 1
                }
            },
        }
    },
    created() {
        this.getCollectLists()
    },
    methods: {
        /**
         * 获取收藏列表
         */
         getCollectLists() {
            getCollectLists(this.listsRequestdata).then(res => {
                this.collectionLists = res.items
            })
        },
        /**
         * 取消收藏
         */
         cancelCollect(id , type , index){
            cancelCollect({
                id:id,
                type:type
            }).then(() => {
                this.collectionLists.splice(index , 1)
                this.$message.success('取消收藏成功');
            })
         }
    }
}
</script>

<style scoped>
.pages {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 120px);
    background-color: #F7F7F8;
}

.main {
    width: 1200px;
    min-height: calc(100vh - 120px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.acount-box {
    width: 888px;
    background-color: #ffffff;
    min-height: calc(100vh - 120px - 16px - 28px);
    margin-top: 16px;
    margin-bottom: 28px;
}

.acount-header {
    width: 888px;
    border-bottom: 1px solid #E8E8E8;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 18px;
}

.acount-header-item {
    height: 60px;
    margin-right: 70px;
    color: #000000;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.acount-header-item-active {
    position: relative;
    color: #DD0722;
}

.acount-header-item-active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #DD0722;
}

.acount-main {
    width: 888px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.follow-lists {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    padding: 0 20px;
    width: 848px;
}

.follow-item {
    padding: 10px 14px;
    border-radius: 22px;
    background-color: #F3F4F7;
    margin-right: 16px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.follow-item .huati-icon {
    width: 26px;
    height: 26px;
}

.follow-item span {
    color: #06121E;
    font-size: 16px;
    margin: 0 6px;
}

.follow-item .x-icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
}
</style>
